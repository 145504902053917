let SERVER_URL = "https://api.motorfirst.ru";
let FILES_URL = "https://api.cms.motorfirst.ru";

const shopData = {
  link: "https://yandex.ru/maps/-/CCU9YXfA1C",
  address: {
    city: "Москва",
    street: "ул. Трифоновская, 45",
    zip: "129272",
  },
};

const workTime = {
  work: "10:00 – 19:00",
  weekend: "11:00 – 18:00",
};

//http://api.mf.dev.guys.agency
//"http://api.cms.dev.guys.agency"
//http://127.0.0.1:3111

switch (process.env.REACT_APP_TYPE) {
  case "local":
    SERVER_URL = "http://127.0.0.1:3211";
    FILES_URL = "http://api.cms.test.motorfirst.ru";
    break;

  case "dev-local":
    SERVER_URL = "https://api.test.motorfirst.ru";
    FILES_URL = "https://api.cms.test.motorfirst.ru";
    break;

  case "dev":
    SERVER_URL = "https://api.test.motorfirst.ru";
    FILES_URL = "https://api.cms.test.motorfirst.ru";
    break;
}

const monthsName = {
  0: "Января",
  1: "Февраля",
  2: "Марта",
  3: "Апреля",
  4: "Мая",
  5: "Июня",
  6: "Июля",
  7: "Августа",
  8: "Сентября",
  9: "Окрября",
  10: "Ноября",
  11: "Декабря",
};

const statusToClass = {
  Новичок: "start",
  Любитель: "bronze",
  Эксперт: "silver",
  Профи: "gold",
  "Сорви голова": "max",
};

const statusToP = {
  Новичок: {
    percent: "3%",
  },
  Любитель: {
    percent: "5%",
  },
  Эксперт: {
    percent: "10%",
    freeDeliv: 1,
  },
  Профи: {
    percent: "15%",
    freeDeliv: 3,
  },
  "Сорви голова": {
    percent: "20%",
    freeDeliv: 5,
  },
};

const statusGrowOrder = [
  "Новичок",
  "Любитель",
  "Эксперт",
  "Профи",
  "Сорви голова",
];

const nameBonusToProdStatus = {
  Новичок: "cashbackstatusstart",
  Любитель: "cashbackstatusbronze",
  Эксперт: "cashbackstatussilver",
  Профи: "cashbackstatusgold",
  "Сорви голова": "cashbackstatusplatinum",
};

const statusMCtoSite = {
  "Заказать у поставщика": {
    name: "Комплектация заказа",
    description: "",
    color: "yellow",
    class: "",
  },
  "Не обработан": {
    name: "Ожидание обработки",
    description: "",
    color: "red",
    class: "red",
  },
  "В работе": {
    name: "Заказ обрабатывается",
    description: "",
    color: "yellow",
    class: "",
  },
  "Ожидание решение": {
    name: "Заказ обрабатывается",
    description: "",
    color: "yellow",
    class: "",
  },
  "Ожидание оплаты": {
    name: "Ожидание оплаты заказа",
    description: "",
    color: "red",
    class: "red",
  },
  "В доставке у курьера": {
    name: "Заказ выдан курьеру",
    description: "",
    color: "yellow",
    class: "",
  },
  "Ожидание возврата": {
    name: "Ожидание возврата",
    description: "",
    color: "yellow",
    class: "",
  },
  Выполнен: {
    name: "Заказ выполнен",
    description: "",
    color: "green",
    class: "green",
  },
  Отмена: {
    name: "Заказ отменен",
    description: "",
    color: "black",
    class: "",
    "text-color": "white",
  },
  "Приедет в шоу-рум": {
    name: "Ожидаем Вас в шоу-руме",
    description: "",
    color: "yellow",
    class: "",
  },
  "В работе у поставщика": {
    name: "Комплектация заказа",
    description: "",
    color: "yellow",
    class: "",
  },
  Предзаказ: {
    name: "Ожидание поставки",
    description: "",
    color: "yellow",
    class: "",
  },
  "В сборке": {
    name: "В сборке",
    description: "",
    color: "orange",
    class: "",
    "text-color": "white",
  },
  "Передано в сборку": {
    name: "В сборке",
    description: "",
    color: "orange",
    class: "",
    "text-color": "white",
  },
  "Ожидание решения": {
    name: "Ожидание решения",
    description: "",
    color: "yellow",
    class: "",
  },
  "Уточнить наличие": {
    name: "Уточнение наличия",
    description: "",
    color: "yellow",
    class: "",
  },
  "Готов к отправке": {
    name: "Готов к отправке",
    description: "",
    color: "orange",
    class: "",
    "text-color": "white",
  },
  "Абонент не доступен - SMS": {
    name: "Мы не смогли до Вас дозвониться",
    description: "",
    color: "red",
    class: "red",
  },
  Оплачен: { name: "Оплачен", description: "", color: "green", class: "green" },
  "Возврат средств": {
    name: "Возврат получен",
    description: "",
    color: "green",
    class: "green",
  },
  "Нашли дешевле": {
    name: "Ожидание обработки",
    description: "",
    color: "red",
    class: "red",
  },
  Связаться: {
    name: "Заказ обрабатывается",
    description: "",
    color: "yellow",
    class: "",
  },
  "Доставлен курьером": {
    name: "Заказ выполнен",
    description: "",
    color: "green",
    class: "green",
  },
  Собран: {
    name: "Собран",
    description: "",
    color: "orange",
    class: "",
    "text-color": "white",
  },
  "Возврат получен": {
    name: "Возврат получен",
    description: "",
    color: "green",
    class: "green",
  },
  "Ожидаем возврат": {
    name: "Ожидание возврата",
    description: "",
    color: "red",
    class: "red",
  },
};

const statusOrderToClass = {
  "Ожидание обработки": "danger",
  "Ожидание оплаты": "danger",
  Выполнен: "access",
  Отмена: "complite",
  "Абонент не доступен - SMS": "danger",
  Оплачен: "access",
  "Возврат средств": "complite",
  "Доставлен курьером": "complite",
};

const sortSizes = [
  "2XS",
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "2XL",
  "3XL",
  "4XL",
  "5XL",
  "6XL",
];

const sizeTableData = {
  509: {
    Одежда: {
      headers: ["", "XS", "S", "M", "L", "XL", "2XL", "3XL"],
      lines: [
        [
          "Грудь",
          "86-91",
          "91-96",
          "99-104",
          "106-111",
          "114-119",
          "123-127",
          "132-137",
        ],
        ["Рукав", "69", "71", "76", "81", "86", "91", "91"],
        ["Талия", "76", "81", "86", "91", "96", "101", "107"],
        ["Шов", "74", "76", "79", "81", "84", "86", "86"],
        ["Рост", "165", "175", "182", ">182", ">185", ">187", ""],
        ["Вес", "60-70", "70-80", "80-90", "90-100", "100-110", "110-125", ""],
      ],
    },

    Перчатки: {
      headers: ["", "XS", "S", "M", "L", "XL", "2XL", "3XL"],
      lines: [
        [
          "Обхват ладони",
          "16-17",
          "17-20",
          "20-22",
          "22-23",
          "23-25",
          "25-27",
          "27-33",
        ],
      ],
    },
    Шлемы: {
      headers: ["Размер", "Обхват"],
      lines: [
        ["XS", "53 - 54"],
        ["S", "55 - 56"],
        ["M", "57 - 58"],
        ["L", "59 - 60"],
        ["XL", "61 - 62"],
        ["2XL", "63 - 64"],
        ["3XL", "64 - 65"],
        ["4XL", "66 - 67"],
        ["Youth Small", "47 - 48"],
        ["Youth Medium", "49 - 50"],
        ["Youth Large", "51 - 52"],
      ],
    },
  },
  FXR: {
    Одежда: {
      withSex: true,
      MAN: {
        headers: ["", "XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
        lines: [
          [
            "Грудь",
            "91-94",
            "97-99",
            "102-104",
            "107-112",
            "114-119",
            "122-127",
            "130-135",
            "137-145",
          ],
          [
            "Талия",
            "74-76",
            "79-81",
            "84-86",
            "89-94",
            "97-102",
            "104-109",
            "112-117",
            "119-127",
          ],
          [
            "Бёдра",
            "89-91",
            "94-97",
            "99-102",
            "104-109",
            "112-117",
            "119-124",
            "127-132",
            "135-142",
          ],
          [
            "Рукав",
            "74-76",
            "74-76",
            "76-79",
            "79-81",
            "81-84",
            "81-84",
            "84-86",
            "84-86",
          ],
          ["Шов", "74", "76", "79", "81", "81", "81", "79", "76"],
          ["Рост", "170", "175", "182", ">182", ">185", ">187", ">187", ">187"],
          [
            "Вес",
            "60-70",
            "70-80",
            "80-90",
            "90-100",
            "100-110",
            "110-125",
            "125-135",
            "",
          ],
        ],
      },
      WOMAN: {
        headers: [
          "Размер",
          "2XS",
          "XS",
          "S",
          "S/M",
          "M",
          "L",
          "L/XL",
          "XL",
          "2XL",
          "3XL",
          "4XL",
        ],
        lines: [
          [
            "Грудь",
            "79-81",
            "84-86",
            "89-91",
            "94-97",
            "99-102",
            "104-107",
            "109-112",
            "114-117",
            "119-122",
            "124-127",
            "132-135",
          ],
          [
            "Талия",
            "64-66",
            "69-71",
            "74-76",
            "79-81",
            "84-86",
            "89-91",
            "94-97",
            "99-102",
            "104-107",
            "109-112",
            "114-117",
          ],
          [
            "Бёдра",
            "81-84",
            "86-89",
            "91-94",
            "97-99",
            "102-107",
            "107-112",
            "112-117",
            "119-124",
            "127-132",
            "132-137",
            "140-145",
          ],
          [
            "Рукав",
            "66-69",
            "71-74",
            "71-74",
            "74-76",
            "74-76",
            "76-79",
            "76-79",
            "81-84",
            "81",
            "81",
            "81",
          ],
          [
            "Шов",
            "71",
            "76",
            "76",
            "76",
            "77",
            "77",
            "76",
            "75",
            "74",
            "74",
            "74",
          ],
        ],
      },
      JUNIOR: {
        headers: ["Размер", "2", "4", "6", "8", "10", "12", "14", "16"],
        lines: [
          ["Рост", "89", "100", "120", "135", "142", "150", "157", "165"],
        ],
      },
    },
    Перчатки: {
      withSex: true,
      MAN: {
        headers: ["Размер", "XS", "S", "M", "L", "XL", "2XL", "3XL"],
        lines: [
          [
            "Обхват ладони",
            "16-17",
            "17-20",
            "20-22",
            "22-23",
            "23-25",
            "25-27",
            "27-33",
          ],
        ],
      },
      WOMAN: {
        headers: ["Размер", "XS", "S", "M", "L", "XL"],
        lines: [
          [
            "Длина от запястья до конца среднего пальца",
            "15,5",
            "16,2",
            "16,9",
            "17,8",
            "18,4",
          ],
        ],
      },
      JUNIOR: {
        headers: ["Размер", "XS", "S", "M", "L"],
        lines: [
          [
            "Длина от запястья до конца среднего пальца",
            "13,5",
            "14,9",
            "15,6",
            "16,3",
          ],
        ],
      },
    },
    Шлемы: {
      lines: [
        ["XS", "53 - 54"],
        ["S", "55 - 56"],
        ["M", "57 - 58"],
        ["L", "59 - 60"],
        ["XL", "61 - 62"],
        ["2XL", "63 - 64"],
        ["3XL", "64 - 65"],
        ["4XL", "66 - 67"],
        ["Youth Small", "47 - 48"],
        ["Youth Medium", "49 - 50"],
        ["Youth Large", "51 - 52"],
      ],
    },
    Обувь: {
      lines: [
        ["FXR Backshift", "", "", "", "", "", "", "", ""],
        [
          "Американский размер",
          "US6",
          "US7",
          "US8",
          "US9",
          "US10",
          "US11",
          "US12",
          "US13",
          "US14",
          "US15",
        ],
        [
          "Европейский размер",
          "38",
          "40",
          "41",
          "42",
          "43",
          "45",
          "46",
          "47",
          "48",
          "49",
        ],
        [
          "Размер стельки СМ",
          "25",
          "25,5",
          "27",
          "27,5",
          "28,5",
          "29,5",
          "30,5",
          "31",
          "31,5",
          "32",
        ],
        [
          "Российский размер",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
        ],
        ["FXR X-Cross", "", "", "", "", "", "", "", ""],
        [
          "Американский размер",
          "US6",
          "US7",
          "US8",
          "US9",
          "US10",
          "US11",
          "US12",
          "US13",
          "US14",
          "US15",
        ],
        [
          "Европейский размер",
          "38",
          "40",
          "41",
          "42",
          "43",
          "45",
          "46",
          "47",
          "48",
          "49",
        ],
        [
          "Размер стельки СМ",
          "25",
          "25,5",
          "27",
          "27,5",
          "28",
          "29",
          "29,5",
          "31",
          "31,5",
          "32",
        ],
        [
          "Российский размер",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
        ],
        ["FXR Helium PRO BOA", "", "", "", "", "", "", "", ""],
        [
          "Американский размер",
          "US6",
          "US7",
          "US8",
          "US9",
          "US10",
          "US11",
          "US12",
          "US13",
          "US14",
          "US15",
        ],
        [
          "Европейский размер",
          "39",
          "40",
          "41",
          "42",
          "43",
          "45",
          "46",
          "47",
          "48",
          "49",
        ],
        [
          "Размер стельки СМ",
          "-",
          "25",
          "26",
          "27",
          "27,5",
          "28,5",
          "29,5",
          "30,5",
          "31",
          "32",
        ],
        [
          "Российский размер",

          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
        ],
        ["FXR Helium Lite BOA", "", "", "", "", "", "", "", ""],
        [
          "Американский размер",
          "US6",
          "US7",
          "US8",
          "US9",
          "US10",
          "US11",
          "US12",
          "US13",
          "US14",
          "US15",
        ],
        [
          "Европейский размер",
          "38",
          "40",
          "41",
          "42",
          "43",
          "45",
          "46",
          "47",
          "48",
          "49",
        ],
        [
          "Размер стельки СМ",
          "25",
          "26",
          "27",
          "27,5",
          "28",
          "29",
          "30",
          "31",
          "32",
          "32,5",
        ],
        [
          "Российский размер",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
        ],
        ["FXR Женская обувь", "", "", "", "", "", "", "", ""],
        [
          "Американский размер",
          "US6",
          "US7",
          "US8",
          "US9",
          "US10",
          "US11",
          "US12",
        ],
        ["Европейский размер", "36", "37", "38", "40", "41", "42", "43"],
        ["Размер стельки СМ", "23,5", "24", "25", "25,5", "26,5", "27", "27,5"],
        ["Российский размер", "35", "36", "37", "39", "40", "41", "42"],
      ],
    },
  },
  TOBE: {
    Одежда: {
      headers: [
        "Размер",
        "Вес",
        "Рост",
        "Грудь",
        "Талия",
        "Бедра",
        "Шов",
        "Рукав",
      ],
      lines: [
        ["2XS", "40-58", "149-162", "78-86", "66-71", "71-78", "75", "78.8"],
        ["XS", "59-65", "163-168", "87-94", "72-78", "79-89", "76", "83.8"],
        ["S", "66-75", "168-177", "95-106", "79-86", "90-99", "77.5", "89"],
        [
          "M",
          "76-87",
          "178-185",
          "107-117",
          "87-96",
          "100-109",
          "78.8",
          "96.5",
        ],
        [
          "L",
          "88-100",
          "182-193",
          "118-127",
          "97-106",
          "110-119",
          "81.2",
          "99",
        ],
        [
          "XL",
          "97-109",
          "186-198",
          "128-137",
          "107-119",
          "120-129",
          "82.6",
          "99",
        ],
        [
          "2XL",
          "107-117",
          "187-200",
          "138-147",
          "120-129",
          "130-140",
          "83.8",
          "102.9",
        ],
        [
          "3XL",
          "115-129",
          "187-200",
          "148-160",
          "130-139",
          "141-150",
          "85.1",
          "102.9",
        ],
        [
          "4XL",
          "130-143",
          "187-200",
          "161-173",
          "140-150",
          "151-160",
          "87.6",
          "105.2",
        ],
      ],
    },
    Обувь: {
      headers: [
        "Длина, см.",
        "24,5",
        "24",
        "25",
        "25,5",
        "26",
        "27",
        "27,5",
        "28",
        "28,5",
        "29",
        "30",
      ],
      lines: [
        [
          "EU",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
        ],
        ["US", "5", "6", "7", "7,5", "8", "9", "10", "10,5", "11", "12", "13"],
        [
          "Рос. размер",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "42,5",
          "43",
          "44",
          "45",
        ],
      ],
    },
    Перчатки: {
      headers: ["Размер", "XS", "S", "M", "L", "XL", "2XL", "3XL"],
      lines: [
        [
          "Обхват ладони",
          "16-17",
          "17-20",
          "20-22",
          "22-23",
          "23-25",
          "25-27",
          "27-33",
        ],
      ],
    },
    Шлемы: {
      lines: [
        ["XS", "53-54"],
        ["S", "55-56"],
        ["M", "57-58"],
        ["L", "59-60"],
        ["XL", "61-62"],
        ["2XL", "63-64"],
        ["3XL", "64-65"],
        ["4XL", "66-67"],
      ],
    },
  },
  SIXS: {
    withSex: true,
    MAN: {
      Одежда: {
        lines: [
          [
            "Рост",
            "130-155",
            "156-170",
            "171-179",
            "180-185",
            "186-190",
            "191-200",
          ],
          ["Вес", "До 60", "До 70", "До 80", "До 90", "До 100", "Более 100"],
        ],
      },
    },
    WOMAN: {
      Одежда: {
        lines: [
          [
            "Рост",
            "130-155",
            "156-170",
            "171-179",
            "180-185",
            "186-190",
            "191-200",
          ],
          ["Вес", "До 60", "До 70", "До 80", "До 90", "До 100", "Более 100"],
        ],
      },
    },
    JUNIOR: {
      Одежда: {
        headers: ["", "2XS", "S", "M", "L"],
        lines: [["Рост", "104", "116", "140", "150"]],
      },
    },
  },
  XBIONIC: {
    withSex: true,
    MAN: {
      Одежда: {
        headers: ["Верх", "S", "M", "L", "XL", "XXL"],
        lines: [
          [
            ["Рост", "166-170", "168-176", "174-182", "180-186", "184-192"],
            ["Обхват груди", "89", "90-97", "98-105", "106-113", "114-122"],
            ["Низ", "S/M", "L/XL", "XXl"],
            ["Рост", "166-176", "174-184", "182-192"],
            ["Обхват талии", "74-84", "86-99", "100-115"],
          ],
        ],
      },
      Шлемы: {
        headers: ["", "1", "2"],
        lines: [["Обхват головы", "54-58", "59-63"]],
      },
    },
    WOMAN: {
      Одежда: {
        headers: ["XS", "S", "M", "L", "XL"],
        lines: [
          ["Рост", "160-163", "163-166", "166-169", "169-172", "172-178"],
          ["Обхват талии", "60-65", "66-73", "74-81", "82-89", "91-102"],
          ["Низ", "S/M", "L/XL", "XXl"],
          ["Рост", "160-163", "163-169", "169-178"],
          ["Обхват талии", "63-73", "74-85", "86-102"],
          ["Обхват бедер", "88-98", "99-108", "109-121"],
        ],
      },
      Шлемы: {
        headers: ["", "1", "2"],
        lines: [["Обхват головы", "54-58", "59-63"]],
      },
    },
  },
  JETHWEAR: {
    Шлемы: {
      lines: [
        ["XS", "53-54"],
        ["S", "55-56"],
        ["M", "57-58"],
        ["L", "59-60"],
        ["XL", "61-62"],
        ["2XL", "63-64"],
      ],
    },
    Перчатки: {
      headers: ["Размер", "XS", "S", "M", "L", "XL", "2XL", "3XL"],
      lines: [
        [
          "Обхват ладони",
          "16-17",
          "17-20",
          "20-22",
          "22-23",
          "23-25",
          "25-27",
          "27-33",
        ],
      ],
    },
  },
  DRAGONFLY: {
    withSex: true,
    MAN: {
      Одежда: {
        headers: ["", "S", "M", "L", "XL", "XXl"],
        lines: [
          ["Рост", "170", "176", "182", "188", "188"],
          ["Обхват груди", "91-97", "99-105", "106-114", "115-121", "122-129"],
          ["Обхват талии", "79-86", "87-94", "95-102", "103-109", "110-117"],
          ["Обхват бедер", "93-98", "99-104", "105-110", "111-116", "117-122"],
        ],
      },
    },
    WOMAN: {
      Одежда: {
        headers: ["", "S", "M", "L"],
        lines: [
          ["Рост", "158/164", "164-170", "170-176"],
          ["Обхват груди", "78-86", "86-94", "94-102"],
          ["Обхват талии", "60-70", "67-77", "75-90"],
          ["Обхват бедет", "87-93", "95-101", "103-109"],
        ],
      },
    },
  },
  CKX: {
    Шлемы: {
      lines: [
        ["XS", "53-54"],
        ["S", "55-56"],
        ["M", "57-58"],
        ["L", "59-60"],
        ["XL", "61-62"],
        ["2XL", "63-64"],
        ["3XL", "64-65"],
        ["4XL", "66-67"],
        ["Youth Small", "47-48"],
        ["Youth Medium", "49-50"],
        ["Youth Large", "51-52"],
      ],
    },
  },
};

export {
  SERVER_URL,
  FILES_URL,
  statusToClass,
  statusToP,
  statusGrowOrder,
  statusMCtoSite,
  statusOrderToClass,
  sortSizes,
  sizeTableData,
  nameBonusToProdStatus,
  monthsName,
  shopData,
  workTime,
};
